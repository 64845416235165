<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="col-md-6 col-lg-4">
            <div class="card">
                <img src="../../../../assets//images//pages//navfleet.png" alt="Card image cap" class="card-img-top" style="height: 200px; object-fit: contain; background-color: #4D4D4F;">
                <div class="card-body">
                    <div class="rowSpace" style="cursor: pointer;" (click)="showNavfleet = !showNavfleet">
                        <h4 style="margin-bottom: 0px;">Web navfleet</h4>
                        <i data-feather="chevron-down"></i>
                    </div>
                    <br>
                    <p class="card-text">If you want to view your vehicles and generate reports for them, click below.</p>
                    <div class="form-group" *ngIf="showNavfleet">
                        <label for="basicInput">Username</label>
                        <input type="text" class="form-control" id="navfleetUsername" placeholder="Username" />
                      </div>
                      <div class="form-group" *ngIf="showNavfleet">
                        <label for="basicInput">Password</label>
                        <input type="text" class="form-control" id="navfleetPassword" placeholder="Password" />
                      </div>
                      <div class="rowSpace">
                        <button type="button" class="btn btn-outline-primary" rippleEffect (click)="navfleetLogin()">Login</button>
                        <button type="button" class="btn btn-primary" rippleEffect *ngIf="showNavfleet">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<form id="postNavfleetDataForm" action="https://navfleet.gr/Default.aspx?externalLogin=true" method="post" target="_blank">
    <input type="hidden" id="navfleet_txtUsername" name="txtUsername" value="vpallis">
    <input type="hidden" id="navfleet_txtPassword" name="txtPassword" value="1234!@#$">
    <input type="hidden" id="navfleet_source" name="source" value="telenavis_account">
</form>