// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'http://127.0.0.1:3000',
  mode: 'development',
  firebaseConfig: {
    apiKey: "AIzaSyDx1PlRl-z9gyEjE1wykIPSwi4unLHdpx4",
    authDomain: "telenavis-account.firebaseapp.com",
    projectId: "telenavis-account",
    storageBucket: "telenavis-account.appspot.com",
    messagingSenderId: "824615202894",
    appId: "1:824615202894:web:ce439500afa2a4b8a1131f",
    measurementId: "G-JYFK47HRXB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
