import { CoreMenu } from '@core/types'

import { locale } from '../translate'

let user = (localStorage.getItem('telenavis_user')) || null;
let array: CoreMenu[] = [
  {
    id: 'dashboard',
    title: (localStorage.getItem('locale') || 'en')=='en' ? locale.EN.Dashboard : locale.GR.Dashboard ,
    type: 'item',
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'profile',
    title: (localStorage.getItem('locale') || 'en')=='en' ? locale.EN.My_Profile : locale.GR.My_Profile ,
    type: 'item',
    icon: 'user',
    url: 'profile'
  },
  {
    id: 'apps',
    title: (localStorage.getItem('locale') || 'en')=='en' ? locale.EN.apps : locale.GR.apps ,
    type: 'item',
    icon: 'user',
    url: 'apps'
  },
  // {
  //   id: 'billing',
  //   title: (localStorage.getItem('locale') || 'en')=='en' ? locale.EN.billing : locale.GR.billing ,
  //   type: 'item',
  //   icon: 'dollar-sign',
  //   url: 'billing'
  // }
]

export const menu: CoreMenu[] = array;
