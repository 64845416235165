import { Component, OnInit } from '@angular/core'
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public contentHeader: object

  constructor(
    private router: Router,
    public service: AuthService,
  ) { 
    let loc = localStorage.getItem('locale') || 'en'
    if(loc!=='en'){
      this.translate();
    }
  }
  
  
  translate(){

  }

  ngOnInit() {

  }
  
}
