import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // domain_local: string = `http://127.0.0.1:3000`
  domain_local: string = `https://acctsvc.telenavis.com`;
  // domain2 = "https://nodegeocoding.telenavis.com/fetchAddress/";

  constructor(
    private http: HttpClient,
  ) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("telenavis_account_token")}`
  })

  // ********************************** STORED DATA ***********************************
  arrayOfHeaders: Array<any> = [];

  jobsDetails: any = {};
  jobsDetailsHeader: any = {};
  
  // ********************************** GENERAL FUNCTION ***********************************
  
  createProfile(params: any) {
    return this.http.post(this.domain_local + `/users/register`, params, {headers: this.headers})
  }
  loginProfile(params: any) {
    return this.http.get(this.domain_local + `/users/${params.uid}`, {headers: this.headers})
  }
  updateProfile(params: any) {
    return this.http.put(this.domain_local + `/users/${params.uid}`, params.data, {headers: this.headers})
  }
  updateProfileCompanies(params: any) {
    return this.http.put(this.domain_local + `/users/companies/${params.uid}`, params.data, {headers: this.headers})
  }

  // ********************************** GENERAL FUNCTION ***********************************
  callFunction(request:string, params: any){
    return new Promise(async (resolve, reject)=>{
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("telenavis_account_token")}`
      })

      let response: any = {}

      if (request=='createProfile'){ response = this.createProfile(params) }
      else if (request=='loginProfile'){ response = this.loginProfile(params) }
      else if (request=='updateProfile'){ response = this.updateProfile(params) }
      else if (request=='updateProfileCompanies'){ response = this.updateProfileCompanies(params) }
     

      response.pipe(timeout(1000*60*30), catchError(e=> {return of(null)})).subscribe(
        async (res: any) => {
          if(res==null){
            console.log(1, "Message!", "We cannot communicate with server! Timeout error!", "Close")
          }else{
            resolve(res)
          }
        },
        (er: any) => { console.error(er) }
      );
    })
  }
}
