import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { countries } from 'app/main/models/countries';
import { AngularFireStorage } from '@angular/fire/compat/storage'; // Import AngularFireStorageModule
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { locale } from '../../../translate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

  roles = [{id: 4, name: "Account Admin"},{id: 3, name: "Member user"},{id: 2, name: "Viewer user"},{id: 1, name: "Billing user"}]
  countries = countries;
  firstName: string = '';
  lastName: string = '';
  country: any = {name: 'Greece'};
  email: string = '';
  companyName: string = "";
  companyId: string = "";
  companySite: string = "";
  company_country: any = {name: 'Greece'};
  companyVAT: string = "";
  companyAddress: string = "";
  companyZip: string = "";
  uid: string = ''
  initialCountry: string = 'GR'
  initialCountryOffice: string = 'GR'
  selectedRoles: any;

  fnError: boolean = false;
  lnError: boolean = false;
  coError: boolean = false;
  emError: boolean = false;
  cnError: boolean = false;

  dial: string = "+30"
  dialOffice: string = "+30"

  constructor(
    private service: AuthService,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private router: Router
  ) 
   {
    let loc = localStorage.getItem('locale') || 'en'
    if(loc!=='en'){
      this.translate();
    }
   }

   user_details: string = locale.EN.user_details;
   firstname: string = locale.EN.firstname;
   lastname: string = locale.EN.lastname;
   countryLbl: string = locale.EN.country;
   office_phone: string = locale.EN.office_phone;
   mobile_phone: string = locale.EN.mobile_phone;
   role: string = locale.EN.role;
   choose_image: string = locale.EN.choose_image;
   company_details: string = locale.EN.company_details;
   company_name: string = locale.EN.Company_Name;
   site_url: string = locale.EN.site_url;
   vat_number: string = locale.EN.vat_number;
   company_address: string = locale.EN.company_address;
   postal_code: string = locale.EN.postal_code;
   Submit: string = locale.EN.Submit;
   Upload: string = locale.EN.Upload;
   required_: string = locale.EN.Upload;
  translate(){
    this.user_details = locale.GR.user_details;
    this.firstname = locale.GR.firstname;
    this.lastname = locale.GR.lastname;
    this.countryLbl = locale.GR.country;
    this.office_phone = locale.GR.office_phone;
    this.mobile_phone = locale.GR.mobile_phone;
    this.role = locale.GR.role;
    this.choose_image = locale.GR.choose_image;
    this.company_details = locale.GR.company_details;
    this.site_url = locale.GR.site_url;
    this.vat_number = locale.GR.vat_number;
    this.company_address = locale.GR.company_address;
    this.postal_code = locale.GR.postal_code;
    this.Submit = locale.GR.Submit;
    this.Upload = locale.GR.Upload;
    this.company_name = locale.GR.Company_Name;
    this.required_ = locale.GR.required_;

  }


  onCountryChange(event: any){
    this.dial = `+${event.dialCode}`
  }
  onCountryChangeOffice(event: any){
    this.dialOffice = `+${event.dialCode}`
  }

  async savePersonalDetails(){
    // console.log(this.selectedRoles)
    this.clearValidations();
    if(!this.checkValidations()){
      let user = {
        firstname: this.firstName,
        lastname: this.lastName,
        country: this.country.name,
        phone: this.dial+'-'+(<HTMLInputElement>document.getElementById('phone-column')).value?.trim(),
        office_phone: this.dialOffice+'-'+(<HTMLInputElement>document.getElementById('phone-column')).value?.trim(),
        logo: this.imageURL,
        selectedRoles: this.selectedRoles
      }
      let res:any = await this.service.callFunction('updateProfile', {uid: this.uid, data: user})
      if(res.success){
        this.toastrCustomSuccess("Done", 'Your details have been updated successfully!');
        this.reloadData();
      }else{
        this.toastrCustomWarning("Οοοps", 'Something went wrong please try again!');
      }
    }else{
      console.log('there is an error');
    }

  }
  async saveCompanyDetails(){
    if(this.companyName.trim() == ''){
      this.cnError = true;
      $('#company-column').addClass('validationError');
    }else{
      this.cnError = false;
      $('#company-column').removeClass('validationError');
      console.log(this.company_country)
      let user = {
        company_name: this.companyName,
        company_id: this.companyId,
        site_url: this.companySite,
        company_country: this.company_country.name,
        vat_number: this.companyVAT,
        company_address: this.companyAddress,
        company_postal_code: this.companyZip,
      }
      let res:any = await this.service.callFunction('updateProfileCompanies', {uid: this.uid, data: user})
      if(res.success){
        this.toastrCustomSuccess("Done", 'Your details have been updated successfully!');
        this.reloadData();
      }else{
        this.toastrCustomWarning("Οοοps", 'Something went wrong please try again!');
      }
    }

  }
  // Custom Success
  toastrCustomSuccess(title: string, msg: string) {
    this.toastr.success(title, msg);
  }
  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(title, msg);
  }
  checkValidations(){
    let isError = false;
    if(this.firstName.trim() == ''){
      isError = true;
      this.fnError = true;
      $('#first-name-column').addClass('validationError');
    }
    if(this.lastName.trim() == ''){
      isError = true;
      this.lnError = true;
      $('#last-name-column').addClass('validationError');
    }
    if(this.country == null){
      isError = true;
      this.coError = true;
      $('#country-column').addClass('validationError');
    }
    // if(this.email.trim() == '' || !this.isValidEmail(this.email.trim())){
    //   isError = true;
    //   this.emError = true;
    //   $('#email-id-column').addClass('validationError');
    // }
    return isError;
  }
  
  isValidEmail(email) {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    // Test the email against the regex pattern
    return emailRegex.test(email);
  }

  clearValidations(){
    $('#first-name-column').removeClass('validationError');
    $('#last-name-column').removeClass('validationError');
    $('#country-column').removeClass('validationError');
    $('#email-id-column').removeClass('validationError');
    this.fnError = false;
    this.lnError = false;
    this.coError = false;
    this.emError = false;
  }

  selectedFile: File;
  imageURL: string = "../../../../assets/images/pages/card-image-4.jpg";
  handleFileInput(event: any){
    this.selectedFile = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.selectedFile);

    this.onUpload(this.selectedFile)
  }

  async reloadData(){
    let login:any = await this.service.callFunction('loginProfile', {uid: this.uid});
    if(login.success){
      localStorage.removeItem('telenavis_user');
      localStorage.setItem('telenavis_user', JSON.stringify(login));
    }
  }

  setInputValues(user:any){
    this.firstName = user.firstname;
    this.lastName = user.lastname;
    this.country = {name: user.country};
    this.email = user.email;
    this.companyName = user.company_name;
    this.companyId = user.company_id;
    this.companySite = user.site_url;
    this.companyVAT = user.vat_number;
    this.companyAddress = user.company_address;
    this.companyZip = user.company_postal_code;
    this.company_country = {name: user.company_country}
    this.imageURL = user.logo ? user.logo: "../../../../assets/images/pages/card-image-4.jpg";
    // this.roles = user.roles;
    this.dial = user.phone.split("-")[0];
    this.dialOffice = user.office_phone?.split("-")[0];
    countries.forEach(element => {
      if(element.dialCode == this.dial){
        this.initialCountry = element.code;
      }
      if(element.dialCode == this.dialOffice){
        this.initialCountryOffice = element.code;
      }
    });
    this.selectedRoles = user.roles;
    (<HTMLInputElement>document.getElementById('phone-column')).value = user.phone?.split("-")[1];
    (<HTMLInputElement>document.getElementById('officephone-column')).value = user.office_phone?.split("-")[1];
  }

  async uploadImage(uid: any, file: any): Promise<string> {
    let otherBucket = this.storage.storage.app.storage("telenavis-account.appspot.com");
    const fileRef = otherBucket.ref(uid).child(file.name);
    if (!!file) {
      const result = await fileRef.put(file).catch(er => { console.log(er) });
      return result.ref.fullPath;
    }
    return ''
  }
  
  async onUpload(file: any) {
    const fullPathInStorage = await this.uploadImage(this.uid, file);
    let otherBucket = this.storage.storage.app.storage("telenavis-account.appspot.com");
    let currentImageUrl = await otherBucket.ref(fullPathInStorage).getDownloadURL()
    this.imageURL = currentImageUrl;
  }

  async ngOnInit(): Promise<void> {
    if(localStorage.getItem('telenavis_user')){
      let _user = JSON.parse(localStorage.getItem('telenavis_user')).data[0];
      this.uid = _user.uid;
      this.setInputValues(_user);

    }else{
      this.router.navigate(['/pages/authentication/login-v2'])
    }
  }

}
