<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <div class="card" style="padding: 24px;">
      <h5>{{user_details}}</h5>
      <div class="desktopRowmobileColumn">
        <form class="form col-md-8 col-15" >
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="first-name-column">{{firstname}} <span style="color: red;">*</span></label>
                <input type="text" id="first-name-column" class="form-control" placeholder="First Name" [(ngModel)]="firstName" name="fname-column" />
                <label *ngIf="fnError" class="redColor">{{required_}}</label>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="last-name-column">{{lastname}} <span style="color: red;">*</span></label>
                <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" [(ngModel)]="lastName" name="lname-column" />
                <label *ngIf="lnError" class="redColor">{{required_}}</label>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="country-floating">{{countryLbl}} <span style="color: red;">*</span></label>
                <div class="form-group" style="min-width: 200px;">
                  <ng-select id="country-column" [items]="countries" bindLabel="name" [(ngModel)]="country" name="country" [loading]="false"> </ng-select>
                  <label *ngIf="coError" class="redColor">{{required_}}</label>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group" style="display: flex; flex-direction: column;">
                <label class="form-label">{{office_phone}}</label>
                <input type="text" id="officephone-column" style="width: 100%;" class="form-control" ng2TelInput [ng2TelInputOptions]="{separateDialCode: true, initialCountry: initialCountry}" (countryChange)="onCountryChangeOffice($event)" />
              </div>
            </div>
  
            <div class="col-md-6 col-12">
              <div class="form-group" style="display: flex; flex-direction: column;">
                <label class="form-label">{{mobile_phone}}</label>
                <input type="text" id="phone-column" style="width: 100%;" class="form-control" ng2TelInput [ng2TelInputOptions]="{separateDialCode: true,  initialCountry: initialCountryOffice}" (countryChange)="onCountryChange($event)" />
              </div>
            </div>
  
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="email-id-column">Email <span style="color: red;">*</span></label>
                <input disabled type="email" id="email-id-column" class="form-control" [(ngModel)]="email" name="email-id-column" placeholder="Email" />
                <!-- <label *ngIf="emError" class="redColor">{{required_}}</label> -->
              </div>
            </div>
  
            <div class="col-sm-6 col-12">
              <label>{{role}}</label>
  
              <div class="form-group">
                <!-- Basic Multi select -->
                <ng-select [items]="roles" [multiple]="true" [(ngModel)]="selectedRoles" [closeOnSelect]="false" [searchable]="false" name="ngSelectRoles" bindLabel="name" placeholder="Select role">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label"> {{ item.name }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                </ng-select>
                <!--/ Basic Multi select -->
              </div>
            </div>
            
            <div class="col-sm-6 col-12">
            <div class="form-group">
              <label for="basicInputFile">{{choose_image}}</label>
              <div class="custom-file">
                  <input type="file" class="custom-file-input" id="customFile" (change)="handleFileInput($event)" />
                  <label class="custom-file-label" for="customFile">{{Upload}}</label>
              </div>
          </div>
        </div>

            <div class="col-12">
              <button type="submit" rippleEffect class="btn btn-primary mr-1" (click)="savePersonalDetails()">{{Submit}}</button>
            </div>
          </div>
        </form>
        <div style="display: flex; flex-direction: column; align-items: center; background-color: #cbcbcb17; justify-content: center;" class="col-md-4 col-9">
          <img [src]="imageURL" style="width: fit-content; max-height: 337px; max-width: 100%; " />
      </div>
      </div>
    </div>
    <div class="card" style="padding: 24px;">
      <h5>{{company_details}}</h5>
      <form class="form">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="company-column">{{company_name}} <span style="color: red;">*</span></label>
              <input type="text" id="company-column" class="form-control" placeholder="Company" [(ngModel)]="companyName" name="company-column" />
              <label *ngIf="cnError" class="redColor">{{required_}}</label>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="address-column">{{site_url}}</label>
              <input type="text" id="siteurl-column" [(ngModel)]="companySite" class="form-control" placeholder="www.telenavis.com" name="siteurl-column" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="address-column">{{vat_number}}</label>
              <input type="text" id="vatnumber-column" [(ngModel)]="companyVAT" class="form-control" placeholder="VAT Number" name="vatnumber-column" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="address-column">{{company_address}}</label>
              <input type="text" id="address-column" [(ngModel)]="companyAddress" class="form-control" placeholder="Address" name="address-column" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="address-column">{{postal_code}}</label>
              <input type="text" id="postalcode-column" [(ngModel)]="companyZip" class="form-control" placeholder="Postal code" name="postalcode-column" />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="country-floating">{{countryLbl}}</label>
              <div class="form-group" style="min-width: 200px;">
                <ng-select id="company_country-column" [items]="countries" bindLabel="name" [(ngModel)]="company_country" name="company_country" [loading]="false"> </ng-select>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button type="submit" rippleEffect class="btn btn-primary mr-1" (click)="saveCompanyDetails()">{{Submit}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>