export const locale = {
    GR: {
        Details: 'Λεπτομέρειες',
        User_details: 'Στοιχεία Χρήστη',
        Image: "Εικόνα",
        Upload: 'Μεταφόρτωση',
        DATE: 'Ημερομηνια',
        UPDATED: 'Ενημερωθηκε',
        CREATED: 'Δημιουργηθηκε',
        COMPANY: 'Εταιρεια',
        LOGO: 'Λογoτυπο',
        Previous: 'Προηγούμενο',
        Submit: 'Αποστολή',
        Next: 'Επόμενο',
        Show: 'Δείξε',
        Back: 'Πίσω',
        Show_me: 'Δείξε μου',
        My_Profile: 'Το προφίλ μου',
        apps: 'Οι εφαρμογές μου',
        Welcome_to_Synlog: 'Καλώς ήρθατε στο Telenavis',
        Please_sign_in: 'Παρακαλώ συνδεθείτε με το λογαριασμό σας για να συνεχίσετε.',
        New_on_our_platform: 'Είστε νέος χρήστης;',
        Create_an_account: 'Δημιουργία λογαριασμού',
        or: 'ή',
        Forgot_Password: 'Ξέχασες τον κωδικό σου;',
        Phone_number: 'Αριθμός τηλεφώνου',
        is_required: 'είναι υποχρεωτικό',
        Company_Name: 'Όνομα εταιρείας',
        Update: 'Ενημέρωση',
        Go_back: 'Επιστροφή',
        Reset: 'Επαναφορά',
        Sign_in: 'Σύνδεση',
        Please_create: 'Παρακαλώ δημιουργήστε τον λογαριασμό σας για να συνεχίσετε.',
        Sign_up: 'Δημιουργία',
        have_an_account: 'Έχετε ήδη λογαριασμό;',
        Logout: 'Αποσύνδεση',
        Dashboard: 'Αρχική',
        What_role: 'Ποιος είναι ο ρόλος σας;',
        Street_place: 'Οδός 12, Αθήνα',
        Status: 'Κατάσταση',
        Search: 'Αναζήτηση',
        vehicles: 'Τα οχήματά μου',
        billing: 'Ο λογαριασμός μου',
        user_details: "Στοιχεία χρήστη",
        firstname: "Όνομα",
        lastname: "Επώνυμο",
        country: "Χώρα ",
        office_phone: "Τηλέφωνο εργασίας",
        mobile_phone: "Κινητό τηλέφωνο",
        role: "Ρόλος",
        choose_image: "Επιλογή εικόνας",
        company_details: "Στοιχεία εταιρείας",
        site_url: "Ιστότοπος",
        vat_number: "VAT",
        company_address: "Διεύθυνση",
        postal_code: "Ταχυδρομικός κωδικός",
        required_: "Το πεδίο είναι υποχρεωτικό",
    },
    EN: {
        Details: 'Details',
        User_details: 'User Details',
        Image: 'Image',
        Upload: 'Upload',
        DATE: 'DATE',
        UPDATED: 'UPDATED',
        CREATED: 'CREATED',
        COMPANY: 'COMPANY',
        LOGO: 'LOGO',
        Previous: 'Previous',
        Submit: 'Submit',
        Next: 'Next',
        Back: 'Back',
        Show_me: 'Show me',
        My_Profile: 'My Profile',
        apps: 'My apps',
        Welcome_to_Synlog: 'Welcome to Telenavis',
        Please_sign_in: 'Please sign-in to your account and start the adventure',
        New_on_our_platform: 'New on our platform?',
        Create_an_account: 'Create an account',
        or: 'or',
        Forgot_Password: 'Forgot Password?',
        Phone_number: 'Phone number',
        is_required: 'is required',
        Company_Name: 'Company Name',
        Update: 'Update',
        Go_back: 'Go back',
        Reset: 'Reset',
        Sign_in: 'Sign in',
        Please_create: 'Please create an account and start the adventure',
        Sign_up: 'Sign up',
        have_an_account: 'Do you have an account?',
        Logout: 'Logout',
        Dashboard: 'Dashboard',
        What_role: 'What is your role?',
        Street_place: 'Street 12, Athens',
        Status: 'Status',
        Search: 'Search',
        vehicles: 'My Vehicles',
        billing: 'My Billing',
        user_details: "User Details",
        firstname: "First Name",
        lastname: "Last Name",
        country: "Country ",
        office_phone: "Office Phone",
        mobile_phone: "Mobile Phone",
        role: "Role",
        choose_image: "Upload image",
        company_details: "Company Details",
        site_url: "Site url",
        vat_number: "VAT Number",
        company_address: "Address",
        postal_code: "Postal code",
        required_: "The field is required",
    }
  }
  