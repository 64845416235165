export const countries = [
  { "code":"AF","name": "Afghanistan", "alpha-3": "AFG", "country-code": "004", "dialCode": "+93"  },  
  { "code":"AX","name": "Åland Islands", "alpha-3": "ALA", "country-code": "248", "dialCode": "+358"  },  
  { "code":"AL","name": "Albania", "alpha-3": "ALB", "country-code": "008", "dialCode": "+355"  },  
  { "code":"DZ","name": "Algeria", "alpha-3": "DZA", "country-code": "012", "dialCode": "+213"  },  
  { "code":"AS","name": "American Samoa", "alpha-3": "ASM", "country-code": "016", "dialCode": "+1684"  },  
  { "code":"AD","name": "Andorra", "alpha-3": "AND", "country-code": "020", "dialCode": "+376"  },  
  { "code":"AO","name": "Angola", "alpha-3": "AGO", "country-code": "024", "dialCode": "+244"  },  
  { "code":"AI","name": "Anguilla", "alpha-3": "AIA", "country-code": "660", "dialCode": "+1264"  },  
  { "code":"AQ","name": "Antarctica", "alpha-3": "ATA", "country-code": "010", "dialCode": "+672"  },  
  { "code":"AG","name": "Antigua and Barbuda", "alpha-3": "ATG", "country-code": "028", "dialCode": "+1268"  },  
  { "code":"AR","name": "Argentina", "alpha-3": "ARG", "country-code": "032", "dialCode": "+54"  },  
  { "code":"AM","name": "Armenia", "alpha-3": "ARM", "country-code": "051", "dialCode": "+374"  },  
  { "code":"AW","name": "Aruba", "alpha-3": "ABW", "country-code": "533", "dialCode": "+297"  },  
  { "code":"AU","name": "Australia", "alpha-3": "AUS", "country-code": "036", "dialCode": "+61"  },  
  { "code":"AT","name": "Austria", "alpha-3": "AUT", "country-code": "040", "dialCode": "+43"  },  
  { "code":"AZ","name": "Azerbaijan", "alpha-3": "AZE", "country-code": "031", "dialCode": "+994"  },  
  { "code":"BS","name": "Bahamas", "alpha-3": "BHS", "country-code": "044", "dialCode": "+1242"  },  
  { "code":"BH","name": "Bahrain", "alpha-3": "BHR", "country-code": "048", "dialCode": "+973"  },  
  { "code":"BD","name": "Bangladesh", "alpha-3": "BGD", "country-code": "050", "dialCode": "+880"  },  
  { "code":"BB","name": "Barbados", "alpha-3": "BRB", "country-code": "052", "dialCode": "+1246"  },  
  { "code":"BY","name": "Belarus", "alpha-3": "BLR", "country-code": "112", "dialCode": "+375"  },  
  { "code":"BE","name": "Belgium", "alpha-3": "BEL", "country-code": "056", "dialCode": "+32"  },  
  { "code":"BZ","name": "Belize", "alpha-3": "BLZ", "country-code": "084", "dialCode": "+501"  },  
  { "code":"BJ","name": "Benin", "alpha-3": "BEN", "country-code": "204", "dialCode": "+229"  },  
  { "code":"BM","name": "Bermuda", "alpha-3": "BMU", "country-code": "060", "dialCode": "+1441"  },  
  { "code":"BT","name": "Bhutan", "alpha-3": "BTN", "country-code": "064", "dialCode": "+975"  },  
  { "code":"BO","name": "Bolivia (Plurinational State of)", "alpha-3": "BOL", "country-code": "068", "dialCode": "+591"  },  
  { "code":"BQ","name": "Bonaire, Sint Eustatius and Saba", "alpha-3": "BES", "country-code": "535", "dialCode": "+599"  },  
  { "code":"BA","name": "Bosnia and Herzegovina", "alpha-3": "BIH", "country-code": "070", "dialCode": "+387"  },  
  { "code":"BW","name": "Botswana", "alpha-3": "BWA", "country-code": "072", "dialCode": "+267"  },  
  { "code":"BV","name": "Bouvet Island", "alpha-3": "BVT", "country-code": "074", "dialCode": "+55"  },  
  { "code":"BR","name": "Brazil", "alpha-3": "BRA", "country-code": "076", "dialCode": "+55"  },  
  { "code":"IO","name": "British Indian Ocean Territory", "alpha-3": "IOT", "country-code": "086", "dialCode": "+246"  },  
  { "code":"BN","name": "Brunei Darussalam", "alpha-3": "BRN", "country-code": "096", "dialCode": "+673"  },  
  { "code":"BG","name": "Bulgaria", "alpha-3": "BGR", "country-code": "100", "dialCode": "+359"  },  
  { "code":"BF","name": "Burkina Faso", "alpha-3": "BFA", "country-code": "854", "dialCode": "+226"  },  
  { "code":"BI","name": "Burundi", "alpha-3": "BDI", "country-code": "108", "dialCode": "+257"  },  
  { "code":"CV","name": "Cabo Verde", "alpha-3": "CPV", "country-code": "132", "dialCode": "+238"  },  
  { "code":"KH","name": "Cambodia", "alpha-3": "KHM", "country-code": "116", "dialCode": "+855"  },  
  { "code":"CM","name": "Cameroon", "alpha-3": "CMR", "country-code": "120", "dialCode": "+237"  },  
  { "code":"CA","name": "Canada", "alpha-3": "CAN", "country-code": "124", "dialCode": "+1"  },  
  { "code":"KY","name": "Cayman Islands", "alpha-3": "CYM", "country-code": "136", "dialCode": "+345"  },  
  { "code":"CF","name": "Central African Republic", "alpha-3": "CAF", "country-code": "140", "dialCode": "+236"  },  
  { "code":"TD","name": "Chad", "alpha-3": "TCD", "country-code": "148", "dialCode": "+235"  },  
  { "code":"CL","name": "Chile", "alpha-3": "CHL", "country-code": "152", "dialCode": "+56"  },  
  { "code":"CN","name": "China", "alpha-3": "CHN", "country-code": "156", "dialCode": "+86"  },  
  { "code":"CX","name": "Christmas Island", "alpha-3": "CXR", "country-code": "162", "dialCode": "+61"  },  
  { "code":"CC","name": "Cocos (Keeling) Islands", "alpha-3": "CCK", "country-code": "166", "dialCode": "+61"  },  
  { "code":"CO","name": "Colombia", "alpha-3": "COL", "country-code": "170", "dialCode": "+57"  },  
  { "code":"KM","name": "Comoros", "alpha-3": "COM", "country-code": "174", "dialCode": "+269"  },  
  { "code":"CG","name": "Congo", "alpha-3": "COG", "country-code": "178", "dialCode": "+242"  },  
  { "code":"CD", "name": "Congo, Democratic Republic of the", "alpha-3": "COD", "country-code": "180", "dialCode": "+243"  },  
  { "code":"CK", "name": "Cook Islands", "alpha-3": "COK", "country-code": "184", "dialCode": "+682"  },  
  { "code":"CR", "name": "Costa Rica", "alpha-3": "CRI", "country-code": "188", "dialCode": "+506"  },  
  { "code":"CI", "name": "Côte d'Ivoire", "alpha-3": "CIV", "country-code": "384", "dialCode": "+225"  },  
  { "code":"HR", "name": "Croatia", "alpha-3": "HRV", "country-code": "191", "dialCode": "+385"  },  
  { "code":"CU", "name": "Cuba", "alpha-3": "CUB", "country-code": "192", "dialCode": "+53"  },  
  { "code":"CW", "name": "Curaçao", "alpha-3": "CUW", "country-code": "531", "dialCode": "+599"  },  
  { "code":"CY", "name": "Cyprus", "alpha-3": "CYP", "country-code": "196", "dialCode": "+357"  },  
  { "code":"CZ", "name": "Czechia", "alpha-3": "CZE", "country-code": "203", "dialCode": "+420"  },  
  { "code":"DK", "name": "Denmark", "alpha-3": "DNK", "country-code": "208", "dialCode": "+45"  },  
  { "code":"DJ", "name": "Djibouti", "alpha-3": "DJI", "country-code": "262", "dialCode": "+253"  },  
  { "code":"DM", "name": "Dominica", "alpha-3": "DMA", "country-code": "212", "dialCode": "+1767"  },  
  { "code":"DO", "name": "Dominican Republic", "alpha-3": "DOM", "country-code": "214", "dialCode": "+1849"  },  
  { "code":"EC", "name": "Ecuador", "alpha-3": "ECU", "country-code": "218", "dialCode": "+593"  },  
  { "code":"EG", "name": "Egypt", "alpha-3": "EGY", "country-code": "818", "dialCode": "+20"  },  
  { "code":"SV", "name": "El Salvador", "alpha-3": "SLV", "country-code": "222", "dialCode": "+503"  },  
  { "code":"GQ", "name": "Equatorial Guinea", "alpha-3": "GNQ", "country-code": "226", "dialCode": "+240"  },  
  { "code":"ER", "name": "Eritrea", "alpha-3": "ERI", "country-code": "232", "dialCode": "+291"  },  
  { "code":"EE", "name": "Estonia", "alpha-3": "EST", "country-code": "233", "dialCode": "+372"  },  
  { "code":"SZ", "name": "Eswatini", "alpha-3": "SWZ", "country-code": "748", "dialCode": "+268"  },  
  { "code":"ET", "name": "Ethiopia", "alpha-3": "ETH", "country-code": "231", "dialCode": "+251"  },  
  { "code":"FK", "name": "Falkland Islands (Malvinas)", "alpha-3": "FLK", "country-code": "238", "dialCode": "+500"  },  
  { "code":"FO", "name": "Faroe Islands", "alpha-3": "FRO", "country-code": "234", "dialCode": "+298"  },  
  { "code":"FJ", "name": "Fiji", "alpha-3": "FJI", "country-code": "242", "dialCode": "+679"  },  
  { "code":"FI", "name": "Finland", "alpha-3": "FIN", "country-code": "246", "dialCode": "+358"  },  
  { "code":"FR", "name": "France", "alpha-3": "FRA", "country-code": "250", "dialCode": "+33"  },  
  { "code":"GF", "name": "French Guiana", "alpha-3": "GUF", "country-code": "254", "dialCode": "+594"  },  
  { "code":"PF", "name": "French Polynesia", "alpha-3": "PYF", "country-code": "258", "dialCode": "+689"  },  
  { "code":"TF", "name": "French Southern Territories", "alpha-3": "ATF", "country-code": "260", "dialCode": "+11"  },  
  { "code":"GA", "name": "Gabon", "alpha-3": "GAB", "country-code": "266", "dialCode": "+241"  },  
  { "code":"GM", "name": "Gambia", "alpha-3": "GMB", "country-code": "270", "dialCode": "+220"  },  
  { "code":"GE", "name": "Georgia", "alpha-3": "GEO", "country-code": "268", "dialCode": "+995"  },  
  { "code":"DE", "name": "Germany", "alpha-3": "DEU", "country-code": "276", "dialCode": "+49"  },  
  { "code":"GH", "name": "Ghana", "alpha-3": "GHA", "country-code": "288", "dialCode": "+233"  },  
  { "code":"GI", "name": "Gibraltar", "alpha-3": "GIB", "country-code": "292", "dialCode": "+350"  },  
  { "code":"GR", "name": "Greece", "alpha-3": "GRC", "country-code": "300", "dialCode": "+30"  },  
  { "code":"GL", "name": "Greenland", "alpha-3": "GRL", "country-code": "304", "dialCode": "+299"  },  
  { "code":"GD", "name": "Grenada", "alpha-3": "GRD", "country-code": "308", "dialCode": "+1473"  },  
  { "code":"GP", "name": "Guadeloupe", "alpha-3": "GLP", "country-code": "312", "dialCode": "+590"  },  
  { "code":"GU", "name": "Guam", "alpha-3": "GUM", "country-code": "316", "dialCode": "+1671"  },  
  { "code":"GT", "name": "Guatemala", "alpha-3": "GTM", "country-code": "320", "dialCode": "+502"  },  
  { "code":"GG", "name": "Guernsey", "alpha-3": "GGY", "country-code": "831", "dialCode": "+44"  },  
  { "code":"GN", "name": "Guinea", "alpha-3": "GIN", "country-code": "324", "dialCode": "+224"  },  
  { "code":"GW", "name": "Guinea-Bissau", "alpha-3": "GNB", "country-code": "624", "dialCode": "+245"  },  
  { "code":"GY", "name": "Guyana", "alpha-3": "GUY", "country-code": "328", "dialCode": "+595"  },  
  { "code":"HT", "name": "Haiti", "alpha-3": "HTI", "country-code": "332", "dialCode": "+509"  },  
  { "code":"HM", "name": "Heard Island and McDonald Islands", "alpha-3": "HMD", "country-code": "334", "dialCode": "+61"  },  
  { "code":"VA", "name": "Holy See", "alpha-3": "VAT", "country-code": "336", "dialCode": "+379"  },  
  { "code":"HN", "name": "Honduras", "alpha-3": "HND", "country-code": "340", "dialCode": "+504"  },  
  { "code":"HK", "name": "Hong Kong", "alpha-3": "HKG", "country-code": "344", "dialCode": "+852"  },  
  { "code":"HU", "name": "Hungary", "alpha-3": "HUN", "country-code": "348", "dialCode": "+36"  },  
  { "code":"IS", "name": "Iceland", "alpha-3": "ISL", "country-code": "352", "dialCode": "+354"  },  
  { "code":"IN", "name": "India", "alpha-3": "IND", "country-code": "356", "dialCode": "+91"  },  
  { "code":"ID", "name": "Indonesia", "alpha-3": "IDN", "country-code": "360", "dialCode": "+62"  },  
  { "code":"IR", "name": "Iran (Islamic Republic of)", "alpha-3": "IRN", "country-code": "364", "dialCode": "+98"  },  
  { "code":"IQ", "name": "Iraq", "alpha-3": "IRQ", "country-code": "368", "dialCode": "+964"  },  
  { "code":"IE", "name": "Ireland", "alpha-3": "IRL", "country-code": "372", "dialCode": "+353"  },  
  { "code":"IM", "name": "Isle of Man", "alpha-3": "IMN", "country-code": "833", "dialCode": "+44"  },  
  { "code":"IL", "name": "Israel", "alpha-3": "ISR", "country-code": "376", "dialCode": "+972"  },  
  { "code":"IT", "name": "Italy", "alpha-3": "ITA", "country-code": "380", "dialCode": "+39"  },  
  { "code":"JM", "name": "Jamaica", "alpha-3": "JAM", "country-code": "388", "dialCode": "+1876"  },  
  { "code":"JP", "name": "Japan", "alpha-3": "JPN", "country-code": "392", "dialCode": "+81"  },  
  { "code":"JE", "name": "Jersey", "alpha-3": "JEY", "country-code": "832", "dialCode": "+44"  },  
  { "code":"JO", "name": "Jordan", "alpha-3": "JOR", "country-code": "400", "dialCode": "+962"  },  
  { "code":"KZ", "name": "Kazakhstan", "alpha-3": "KAZ", "country-code": "398", "dialCode": "+77"  },  
  { "code":"KE", "name": "Kenya", "alpha-3": "KEN", "country-code": "404", "dialCode": "+254"  },  
  { "code":"KI", "name": "Kiribati", "alpha-3": "KIR", "country-code": "296", "dialCode": "+686"  },  
  { "code":"KP", "name": "Korea (Democratic People's Republic of)", "alpha-3": "PRK", "country-code": "408", "dialCode": "+850"  },  
  { "code":"KR", "name": "Korea, Republic of", "alpha-3": "KOR", "country-code": "410", "dialCode": "+82"  },  
  { "code":"KW", "name": "Kuwait", "alpha-3": "KWT", "country-code": "414", "dialCode": "+965"  },  
  { "code":"KG", "name": "Kyrgyzstan", "alpha-3": "KGZ", "country-code": "417", "dialCode": "+996"  },  
  { "code":"LA", "name": "Lao People's Democratic Republic", "alpha-3": "LAO", "country-code": "418", "dialCode": "+856"  },  
  { "code":"LV", "name": "Latvia", "alpha-3": "LVA", "country-code": "428", "dialCode": "+371"  },  
  { "code":"LB", "name": "Lebanon", "alpha-3": "LBN", "country-code": "422", "dialCode": "+961"  },  
  { "code":"LS", "name": "Lesotho", "alpha-3": "LSO", "country-code": "426", "dialCode": "+266"  },  
  { "code":"LR", "name": "Liberia", "alpha-3": "LBR", "country-code": "430", "dialCode": "+231"  },  
  { "code":"LY", "name": "Libya", "alpha-3": "LBY", "country-code": "434", "dialCode": "+218"  },  
  { "code":"LI", "name": "Liechtenstein", "alpha-3": "LIE", "country-code": "438", "dialCode": "+423"  },  
  { "code":"LT", "name": "Lithuania", "alpha-3": "LTU", "country-code": "440", "dialCode": "+370"  },  
  { "code":"LU", "name": "Luxembourg", "alpha-3": "LUX", "country-code": "442", "dialCode": "+352"  },  
  { "code":"MO", "name": "Macao", "alpha-3": "MAC", "country-code": "446", "dialCode": "+853"  },  
  { "code":"MG", "name": "Madagascar", "alpha-3": "MDG", "country-code": "450", "dialCode": "+261"  },  
  { "code":"MW", "name": "Malawi", "alpha-3": "MWI", "country-code": "454", "dialCode": "+265"  },  
  { "code":"MY", "name": "Malaysia", "alpha-3": "MYS", "country-code": "458", "dialCode": "+60"  },  
  { "code":"MV", "name": "Maldives", "alpha-3": "MDV", "country-code": "462", "dialCode": "+960"  },  
  { "code":"ML", "name": "Mali", "alpha-3": "MLI", "country-code": "466", "dialCode": "+223"  },  
  { "code":"MT", "name": "Malta", "alpha-3": "MLT", "country-code": "470", "dialCode": "+356"  },  
  { "code":"MH", "name": "Marshall Islands", "alpha-3": "MHL", "country-code": "584", "dialCode": "+692"  },  
  { "code":"MQ", "name": "Martinique", "alpha-3": "MTQ", "country-code": "474", "dialCode": "+596"  },  
  { "code":"MR", "name": "Mauritania", "alpha-3": "MRT", "country-code": "478", "dialCode": "+222"  },  
  { "code":"MU", "name": "Mauritius", "alpha-3": "MUS", "country-code": "480", "dialCode": "+230"  },  
  { "code":"YT", "name": "Mayotte", "alpha-3": "MYT", "country-code": "175", "dialCode": "+262"  },  
  { "code":"MX", "name": "Mexico", "alpha-3": "MEX", "country-code": "484", "dialCode": "+52"  },  
  { "code":"FM", "name": "Micronesia (Federated States of)", "alpha-3": "FSM", "country-code": "583", "dialCode": "+691"  },  
  { "code":"MD", "name": "Moldova, Republic of", "alpha-3": "MDA", "country-code": "498", "dialCode": "+373"  },  
  { "code":"MC", "name": "Monaco", "alpha-3": "MCO", "country-code": "492", "dialCode": "+377"  },  
  { "code":"MN", "name": "Mongolia", "alpha-3": "MNG", "country-code": "496", "dialCode": "+976"  },  
  { "code":"ME", "name": "Montenegro", "alpha-3": "MNE", "country-code": "499", "dialCode": "+382"  },  
  { "code":"MS", "name": "Montserrat", "alpha-3": "MSR", "country-code": "500", "dialCode": "+1664"  },  
  { "code":"MA", "name": "Morocco", "alpha-3": "MAR", "country-code": "504", "dialCode": "+212"  },  
  { "code":"MZ", "name": "Mozambique", "alpha-3": "MOZ", "country-code": "508", "dialCode": "+258"  },  
  { "code":"MM", "name": "Myanmar", "alpha-3": "MMR", "country-code": "104", "dialCode": "+95"  },  
  { "code":"NA", "name": "Namibia", "alpha-3": "NAM", "country-code": "516", "dialCode": "+264"  },  
  { "code":"NR", "name": "Nauru", "alpha-3": "NRU", "country-code": "520", "dialCode": "+674"  },  
  { "code":"NP", "name": "Nepal", "alpha-3": "NPL", "country-code": "524", "dialCode": "+977"  },  
  { "code":"NL", "name": "Netherlands", "alpha-3": "NLD", "country-code": "528", "dialCode": "+31"  },  
  { "code":"NC", "name": "New Caledonia", "alpha-3": "NCL", "country-code": "540", "dialCode": "+687"  },  
  { "code":"NZ", "name": "New Zealand", "alpha-3": "NZL", "country-code": "554", "dialCode": "+64"  },  
  { "code":"NI", "name": "Nicaragua", "alpha-3": "NIC", "country-code": "558", "dialCode": "+505"  },  
  { "code":"NE", "name": "Niger", "alpha-3": "NER", "country-code": "562", "dialCode": "+227"  },  
  { "code":"NG", "name": "Nigeria", "alpha-3": "NGA", "country-code": "566", "dialCode": "+234"  },  
  { "code":"NU", "name": "Niue", "alpha-3": "NIU", "country-code": "570", "dialCode": "+683"  },  
  { "code":"NF", "name": "Norfolk Island", "alpha-3": "NFK", "country-code": "574", "dialCode": "+672"  },  
  { "code":"MK", "name": "North Macedonia", "alpha-3": "MKD", "country-code": "807", "dialCode": "+389"  },  
  { "code":"MP", "name": "Northern Mariana Islands", "alpha-3": "MNP", "country-code": "580", "dialCode": "+1670"  },  
  { "code":"NO", "name": "Norway", "alpha-3": "NOR", "country-code": "578", "dialCode": "+47"  },  
  { "code":"OM", "name": "Oman", "alpha-3": "OMN", "country-code": "512", "dialCode": "+968"  },  
  { "code":"PK", "name": "Pakistan", "alpha-3": "PAK", "country-code": "586", "dialCode": "+92"  },  
  { "code":"PW", "name": "Palau", "alpha-3": "PLW", "country-code": "585", "dialCode": "+680"  },  
  { "code":"PS", "name": "Palestine, State of", "alpha-3": "PSE", "country-code": "275", "dialCode": "+970"  },  
  { "code":"PA", "name": "Panama", "alpha-3": "PAN", "country-code": "591", "dialCode": "+507"  },  
  { "code":"PG", "name": "Papua New Guinea", "alpha-3": "PNG", "country-code": "598", "dialCode": "+675"  },  
  { "code":"PY", "name": "Paraguay", "alpha-3": "PRY", "country-code": "600", "dialCode": "+595"  },  
  { "code":"PE", "name": "Peru", "alpha-3": "PER", "country-code": "604", "dialCode": "+51"  },  
  { "code":"PH", "name": "Philippines", "alpha-3": "PHL", "country-code": "608", "dialCode": "+63"  },  
  { "code":"PN", "name": "Pitcairn", "alpha-3": "PCN", "country-code": "612", "dialCode": "+872"  },  
  { "code":"PL", "name": "Poland", "alpha-3": "POL", "country-code": "616", "dialCode": "+48"  },  
  { "code":"PT", "name": "Portugal", "alpha-3": "PRT", "country-code": "620", "dialCode": "+351"  },  
  { "code":"PR", "name": "Puerto Rico", "alpha-3": "PRI", "country-code": "630", "dialCode": "+1939"  },  
  { "code":"QA", "name": "Qatar", "alpha-3": "QAT", "country-code": "634", "dialCode": "+974"  },  
  { "code":"RE", "name": "Réunion", "alpha-3": "REU", "country-code": "638", "dialCode": "+262"  },  
  { "code":"RO", "name": "Romania", "alpha-3": "ROU", "country-code": "642", "dialCode": "+40"  },  
  { "code":"RU", "name": "Russian Federation", "alpha-3": "RUS", "country-code": "643", "dialCode": "+7"  },  
  { "code":"RW", "name": "Rwanda", "alpha-3": "RWA", "country-code": "646", "dialCode": "+250"  },  
  { "code":"BL", "name": "Saint Barthélemy", "alpha-3": "BLM", "country-code": "652", "dialCode": "+590"  },  
  { "code":"SH", "name": "Saint Helena, Ascension and Tristan da Cunha", "alpha-3": "SHN", "country-code": "654", "dialCode": "+290"  },  
  { "code":"KN", "name": "Saint Kitts and Nevis", "alpha-3": "KNA", "country-code": "659", "dialCode": "+1869"  },  
  { "code":"LC", "name": "Saint Lucia", "alpha-3": "LCA", "country-code": "662", "dialCode": "+1758"  },  
  { "code":"MF", "name": "Saint Martin (French part)", "alpha-3": "MAF", "country-code": "663", "dialCode": "+590"  },  
  { "code":"PM", "name": "Saint Pierre and Miquelon", "alpha-3": "SPM", "country-code": "666", "dialCode": "+508"  },  
  { "code":"VC", "name": "Saint Vincent and the Grenadines", "alpha-3": "VCT", "country-code": "670", "dialCode": "+1784"  },  
  { "code":"WS", "name": "Samoa", "alpha-3": "WSM", "country-code": "882", "dialCode": "+685"  },  
  { "code":"SM", "name": "San Marino", "alpha-3": "SMR", "country-code": "674", "dialCode": "+378"  },  
  { "code":"ST", "name": "Sao Tome and Principe", "alpha-3": "STP", "country-code": "678", "dialCode": "+239"  },  
  { "code":"SA", "name": "Saudi Arabia", "alpha-3": "SAU", "country-code": "682", "dialCode": "+966"  },  
  { "code":"SN", "name": "Senegal", "alpha-3": "SEN", "country-code": "686", "dialCode": "+221"  },  
  { "code":"RS", "name": "Serbia", "alpha-3": "SRB", "country-code": "688", "dialCode": "+381"  },  
  { "code":"SC", "name": "Seychelles", "alpha-3": "SYC", "country-code": "690", "dialCode": "+248"  },  
  { "code":"SL", "name": "Sierra Leone", "alpha-3": "SLE", "country-code": "694", "dialCode": "+232"  },  
  { "code":"SG", "name": "Singapore", "alpha-3": "SGP", "country-code": "702", "dialCode": "+65"  },  
  { "code":"SX", "name": "Sint Maarten (Dutch part)", "alpha-3": "SXM", "country-code": "534", "dialCode": "+721"  },  
  { "code":"SK", "name": "Slovakia", "alpha-3": "SVK", "country-code": "703", "dialCode": "+421"  },  
  { "code":"SI", "name": "Slovenia", "alpha-3": "SVN", "country-code": "705", "dialCode": "+386"  },  
  { "code":"SB", "name": "Solomon Islands", "alpha-3": "SLB", "country-code": "090", "dialCode": "+677"  },  
  { "code":"SO", "name": "Somalia", "alpha-3": "SOM", "country-code": "706", "dialCode": "+252"  },  
  { "code":"ZA", "name": "South Africa", "alpha-3": "ZAF", "country-code": "710", "dialCode": "+27"  },  
  { "code":"GS", "name": "South Georgia and the South Sandwich Islands", "alpha-3": "SGS", "country-code": "239", "dialCode": "+500"  },  
  { "code":"SS", "name": "South Sudan", "alpha-3": "SSD", "country-code": "728", "dialCode": "+211"  },  
  { "code":"ES", "name": "Spain", "alpha-3": "ESP", "country-code": "724", "dialCode": "+34"  },  
  { "code":"LK", "name": "Sri Lanka", "alpha-3": "LKA", "country-code": "144", "dialCode": "+94"  },  
  { "code":"SD", "name": "Sudan", "alpha-3": "SDN", "country-code": "729", "dialCode": "+249"  },  
  { "code":"SR", "name": "Suriname", "alpha-3": "SUR", "country-code": "740", "dialCode": "+597"  },  
  { "code":"SJ", "name": "Svalbard and Jan Mayen", "alpha-3": "SJM", "country-code": "744", "dialCode": "+47"  },  
  { "code":"SE", "name": "Sweden", "alpha-3": "SWE", "country-code": "752", "dialCode": "+46"  },  
  { "code":"CH", "name": "Switzerland", "alpha-3": "CHE", "country-code": "756", "dialCode": "+41"  },  
  { "code":"SY", "name": "Syrian Arab Republic", "alpha-3": "SYR", "country-code": "760", "dialCode": "+963"  },  
  { "code":"TW", "name": "Taiwan, Province of China", "alpha-3": "TWN", "country-code": "158", "dialCode": "+886"  },  
  { "code":"TJ", "name": "Tajikistan", "alpha-3": "TJK", "country-code": "762", "dialCode": "+992"  },  
  { "code":"TZ", "name": "Tanzania, United Republic of", "alpha-3": "TZA", "country-code": "834", "dialCode": "+255"  },  
  { "code":"TH", "name": "Thailand", "alpha-3": "THA", "country-code": "764", "dialCode": "+66"  },  
  { "code":"TL", "name": "Timor-Leste", "alpha-3": "TLS", "country-code": "626", "dialCode": "+670"  },  
  { "code":"TG", "name": "Togo", "alpha-3": "TGO", "country-code": "768", "dialCode": "+228"  },  
  { "code":"TK", "name": "Tokelau", "alpha-3": "TKL", "country-code": "772", "dialCode": "+690"  },  
  { "code":"TO", "name": "Tonga", "alpha-3": "TON", "country-code": "776", "dialCode": "+676"  },  
  { "code":"TT", "name": "Trinidad and Tobago", "alpha-3": "TTO", "country-code": "780", "dialCode": "+1868"  },  
  { "code":"TN", "name": "Tunisia", "alpha-3": "TUN", "country-code": "788", "dialCode": "+216"  },  
  { "code":"TR", "name": "Turkey", "alpha-3": "TUR", "country-code": "792", "dialCode": "+90"  },  
  { "code":"TM", "name": "Turkmenistan", "alpha-3": "TKM", "country-code": "795", "dialCode": "+993"  },  
  { "code":"TC", "name": "Turks and Caicos Islands", "alpha-3": "TCA", "country-code": "796", "dialCode": "+1649"  },  
  { "code":"TV", "name": "Tuvalu", "alpha-3": "TUV", "country-code": "798", "dialCode": "+688"  },  
  { "code":"UG", "name": "Uganda", "alpha-3": "UGA", "country-code": "800", "dialCode": "+256"  },  
  { "code":"UA", "name": "Ukraine", "alpha-3": "UKR", "country-code": "804", "dialCode": "+380"  },  
  { "code":"AE", "name": "United Arab Emirates", "alpha-3": "ARE", "country-code": "784", "dialCode": "+971"  },  
  { "code":"GB", "name": "United Kingdom of Great Britain and Northern Ireland", "alpha-3": "GBR", "country-code": "826", "dialCode": "+44"  },  
  { "code":"US", "name": "United States of America", "alpha-3": "USA", "country-code": "840", "dialCode": "+1"  },  
  { "code":"UM", "name": "United States Minor Outlying Islands", "alpha-3": "UMI", "country-code": "581", "dialCode": "+1"  },  
  { "code":"UY", "name": "Uruguay", "alpha-3": "URY", "country-code": "858", "dialCode": "+598"  },  
  { "code":"UZ", "name": "Uzbekistan", "alpha-3": "UZB", "country-code": "860", "dialCode": "+998"  },  
  { "code":"VU", "name": "Vanuatu", "alpha-3": "VUT", "country-code": "548", "dialCode": "+678"  },  
  { "code":"VE", "name": "Venezuela (Bolivarian Republic of)", "alpha-3": "VEN", "country-code": "862", "dialCode": "+58"  },  
  { "code":"VN", "name": "Viet Nam", "alpha-3": "VNM", "country-code": "704", "dialCode": "+84"  },  
  { "code":"VG", "name": "Virgin Islands (British)", "alpha-3": "VGB", "country-code": "092", "dialCode": "+1284"  },  
  { "code":"VI", "name": "Virgin Islands (U.S.)", "alpha-3": "VIR", "country-code": "850", "dialCode": "+1340"  },  
  { "code":"WF", "name": "Wallis and Futuna", "alpha-3": "WLF", "country-code": "876", "dialCode": "+681"  },  
  { "code":"EH", "name": "Western Sahara", "alpha-3": "ESH", "country-code": "732", "dialCode": "+212"  },  
  { "code":"YE", "name": "Yemen", "alpha-3": "YEM", "country-code": "887", "dialCode": "+967"  },  
  { "code":"ZM", "name": "Zambia", "alpha-3": "ZMB", "country-code": "894", "dialCode": "+260"  },  
  { "code":"ZW", "name": "Zimbabwe", "alpha-3": "ZWE", "country-code": "716", "dialCode": "+263" }
  ]
  