import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-myapps',
  templateUrl: './myapps.component.html',
  styleUrls: ['./myapps.component.scss']
})
export class MyappsComponent implements OnInit {

  showNavfleet: boolean = true;

  constructor(
    private toastr: ToastrService,) { }

  navfleetLogin(){
    let username = (<HTMLInputElement>document.getElementById("navfleetUsername")).value?.trim();
    let password = (<HTMLInputElement>document.getElementById("navfleetPassword")).value?.trim();

    if(username == '' || username == null || password == '' || password == null){
      this.toastrCustomWarning("You don't have the right credentials!", "Οοοps");
    }else{
      (<HTMLInputElement>document.getElementById("navfleet_txtUsername")).value = username;
      (<HTMLInputElement>document.getElementById("navfleet_txtPassword")).value = password;
  
      const form = document.getElementById('postNavfleetDataForm') as HTMLFormElement;
      if (form) {
        // Submit the form to open a new tab with POST data
        form.submit();
      }
    };
    }
   

  ngOnInit(): void {
  }

  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(title, msg);
  }
}
