import { Component, OnInit } from '@angular/core'
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Router } from '@angular/router';
import { locale } from '../../translate';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  public contentHeader: object
  constructor(private service: AuthService,
    private router: Router) {
    
    let loc = localStorage.getItem('locale') || 'en'
    if(loc!=='en'){
      this.translate();
    }
  }
  
  
  translate(){

  }

  ngOnInit() {
    
  }
}
